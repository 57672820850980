




















import Vue from "vue";
// import { defineComponent } from "vue";
import { getModule } from "vuex-module-decorators";
import { BannerAd } from "@/types/interfaces";

const blankAd: BannerAd = {
    slots: [],
    image: "",
    url: "",
    title: "",
    altText: ""
};

export default Vue.extend({
    props: {
        slotName: {
            default: "",
            required: true
        }
    },
    mounted() {
        this.setupAds();
    },
    data() {
        return {
            currentAd: blankAd as BannerAd
        };
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },

        bannerAds(): Array<BannerAd> {
            return this.optionsStore.options.bannerAds
                ? this.optionsStore.options.bannerAds
                : [];
        },

        validAds(): Array<BannerAd> {
            const bannerAds = this.bannerAds;

            return bannerAds && bannerAds.length
                ? bannerAds.filter((ad: BannerAd) => {
                      return (
                          ad.slots.includes("all") ||
                          ad.slots.includes(this.slotName)
                      );
                  })
                : [];
        }
    },
    methods: {
        async setupAds() {
            await this.$store.dispatch("getOptions", ["bannerAds"]);
            this.selectRandomAd();
        },
        selectRandomAd() {
            const validAds = this.validAds;
            if (validAds.length) {
                const rand = Math.floor(
                    Math.random() * Math.floor(validAds.length)
                );
                this.currentAd = validAds[rand];
            } else {
                this.currentAd = blankAd;
            }
        },

        adAltText(ad: BannerAd) {
            return ad.altText && ad.altText !== "" ? ad.altText : "Ad Banner";
        },

        adTitle(ad: BannerAd) {
            return ad.title && ad.title !== "" ? ad.title : "Advertisement";
        }
    }
});
