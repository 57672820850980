import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Store from "../index";
import { FeaturedVideoObject } from "@/types/interfaces";
import apiSvc from "../../services/api";

@Module({
    dynamic: true,
    store: Store,
    name: "getFeaturedVideo",
    namespaced: true
})
export default class GetFeaturedVideoModule extends VuexModule {
    featuredVideo: FeaturedVideoObject = {
        videoId: "",
        videoUrl: "",
        title: "",
        startDate: 0,
        endDate: 0,
        thumbnail: "",
        isActive: false
    };

    @Mutation
    public setFeaturedVideo(data: FeaturedVideoObject) {
        this.featuredVideo = data;
    }

    @Action({ commit: "setFeaturedVideo" })
    getFeaturedVideo() {
        return new Promise((resolve, reject) => {
            apiSvc
                .getFeaturedVideo()
                .then((response: any) => {
                    return resolve(response.data);
                })
                .catch((error: any) => {
                    return reject(error);
                });
        });
    }
}
