import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Store from "../index";
import {
    AttendeeElasticSearchResult,
    AttendeeSwimlaneObject
} from "@/types/interfaces";
import { getApiClient } from "@/services/api";

@Module({
    dynamic: true,
    store: Store,
    name: "getFeaturedAttendees",
    namespaced: true
})
export default class GetFeaturedAttendeesModule extends VuexModule {
    featuredAttendeeSwimlanes: Array<AttendeeSwimlaneObject> = [];
    featuredAttendeesLoaded = false;

    get hasData() {
        const hasSomedata = this.featuredAttendeeSwimlanes.some((item) => {
            return Array.isArray(item.data) && item.data.length;
        });
        return hasSomedata;
    }

    @Mutation
    public setFeaturedAttendees(data: Array<AttendeeSwimlaneObject>) {
        this.featuredAttendeeSwimlanes = data;
    }

    @Mutation
    public setFeaturedAttendeesLoaded(value: boolean) {
        this.featuredAttendeesLoaded = value;
    }

    @Action({})
    async getFeaturedAttendees(swimlanes: Array<AttendeeSwimlaneObject>) {
        this.context.commit("setFeaturedAttendeesLoaded", false);
        this.context.commit("setFeaturedAttendees", []);

        // TODO - improve this code.
        // Use Promise.allSettled
        const promiseArray: Array<Promise<AttendeeSwimlaneObject>> = [];
        const returnArray: Array<AttendeeSwimlaneObject> = [...swimlanes];

        returnArray.forEach((swimlane) => {
            const aPromise: Promise<AttendeeSwimlaneObject> = new Promise(
                (resolve, reject) => {
                    const token = this.context.rootGetters.idToken;
                    getApiClient()
                        .post(
                            `/search`,
                            {
                                randomSampling: true,
                                perPage: swimlane.count,
                                categories: swimlane.categories
                            },
                            {
                                headers: {
                                    Authorization: `bearer ${token}`
                                }
                            }
                        )
                        .then((response) => {
                            const returnValue = {
                                key: swimlane.key,
                                categories: swimlane.categories,
                                data: []
                            };

                            if (response.data && response.data.hits) {
                                const hits = response.data.hits;
                                returnValue.data = hits.map(
                                    (item: AttendeeElasticSearchResult) => {
                                        const returnValue = Vue.prototype.MgFormatElasticSearchResult(
                                            item._source
                                        );
                                        return returnValue;
                                    }
                                );
                            }

                            return resolve(returnValue);
                        })
                        .catch((error) => {
                            return reject(error);
                        });
                }
            );
            promiseArray.push(aPromise.catch((error) => error));
        });

        await Promise.all(promiseArray).then((promises) => {
            returnArray.forEach((swimlane) => {
                const thisData = promises.find((promise) => {
                    const x = promise as AttendeeSwimlaneObject;
                    return x.key === swimlane.key;
                });

                if (thisData) {
                    swimlane.data = thisData.data;
                } else {
                    swimlane.data = [];
                }
            });

            this.setFeaturedAttendeesLoaded(true);
            this.setFeaturedAttendees(returnArray);
        });
    }
}
