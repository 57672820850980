var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-container"},[_c('h1',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.siteName))]),_c('div',{staticClass:"bg-center bg-cover pt-12 pb-12 bg-homepage--video",style:(_vm.backgroundImageIfPresent(
                _vm.videoBackgroundImage,
                'videoBackgroundImage',
                _vm.useVideoBgOverlay
            ))},[(_vm.isLoadingVideo)?_c('Spinners',{staticClass:"my-16"}):(_vm.featuredVideo && _vm.featuredVideo.videoUrl)?_c('div',{key:"db-video",staticClass:"m-auto w-full lg:w-3/5 "},[_c('video-component',{attrs:{"sessionData":{
                    sessionId: null,
                    title: _vm.featuredVideo.title
                },"options":{
                    autoplay: false,
                    controls: true,
                    sources: [
                        {
                            src: _vm.featuredVideo.videoUrl,
                            type: 'application/x-mpegURL'
                        }
                    ],
                    poster: _vm.featuredVideo.thumbnail
                }}})],1):(_vm.featuredItem && !_vm.featuredVideo && !_vm.featuredVideo.videoUrl)?_c('div',{staticClass:"m-auto w-full lg:w-3/5 "},[_c('img',{staticClass:"object-contain w-full h-full",attrs:{"src":_vm.featuredItem,"alt":"Featured Item"}})]):(_vm.notProduction && !_vm.featuredItem)?_c('div',{key:"default-video",staticClass:"m-auto w-full lg:w-3/5  block"},[_c('div',{staticClass:"videoWrapper"},[_c('iframe',{staticClass:"video",attrs:{"src":"https://player.vimeo.com/video/403106834","width":"640","height":"564","title":"Introduction to Virtual Meeting Platform","frameborder":"0","allow":"autoplay; fullscreen","allowfullscreen":"","muted":""}})])]):_vm._e()],1),_c('ActionIcon',{attrs:{"innerClasses":_vm.cssVariables['--action-container-bg']}}),(_vm.isLoadingSessions)?_c('Spinners',{staticClass:"my-10"}):(_vm.useRecommendedSection && _vm.sessionData.length)?_c('div',{staticClass:"bg-cover bg-right py-12",style:([
            "background: var(--home-featured-content-bg-color)",
            _vm.backgroundImageIfPresent(_vm.recommendedSectionBg)
        ])},[_c('div',{staticClass:"container py-3 px-4"},[_c('h2',{staticClass:"mb-10 text-center",style:("color: var(--home-featuredSession-text-color)")},[_vm._v(" "+_vm._s(_vm.homeLabel.previousSessionHeader ? _vm.homeLabel.previousSessionHeader : "PREVIOUS SESSIONS")+" ")]),_c('div',{staticClass:"wrapper"},[_c('SessionSwimLane',{attrs:{"sessionData":_vm.sessionData,"arrowColor":_vm.textColor('slider-arrows')}})],1)])]):_vm._e(),(_vm.isLoadingFeaturedContent)?_c('Spinners',{staticClass:"my-10"}):(
            _vm.featuredContent &&
                _vm.featuredContent.length &&
                _vm.sectionToUse.featuredContentSwimlane.useSection
        )?_c('div',{staticClass:"bg-cover",class:_vm.bgColor('previous'),style:([
            _vm.backgroundImageIfPresent(_vm.recommendedSectionBg),
            "background-color: var(--home-featured-content-bg-color)"
        ])},[_c('div',{staticClass:"container py-12 px-4"},[_c('h2',{staticClass:"section-title text-center mb-8",style:("color: var(--home-prevsession-text-color)")},[_vm._v(" "+_vm._s(_vm.homeLabel.featuredContentHeader ? _vm.homeLabel.featuredContentHeader : "Featured")+" ")]),_c('div',{staticClass:"wrapper"},[_c('SessionSwimLane',{attrs:{"sessionData":_vm.featuredContent,"contentType":"featuredContent","arrowColor":_vm.textColor('slider-arrows')}})],1)])]):_vm._e(),(_vm.sectionToUse.twitterEmbed)?_c('div',{staticClass:"py-20 bg-primary grid",attrs:{"id":"twitter-timeline--embed"}},[_c('article',{staticClass:"w-full flex flex-col  md:flex-row container m-auto"},[_c('div',{staticClass:"w-full md:w-2/5 border-r-1 container px-6",domProps:{"innerHTML":_vm._s(_vm.sectionToUse.twitterEmbed)}}),_c('div',{staticClass:"ml-12 md:3/4"},_vm._l((_vm.attendeeSwimlane),function(att){return _c('div',{key:att.category},[_c('h4',{staticClass:"text-white text-2xl pb-4"},[_vm._v(" "+_vm._s(att.headerLabel)+" ")]),_c('div',{staticClass:"grid gap-6 md:grid-cols-3"},_vm._l((_vm.attendeeTier),function(attendee){return _c('AttendeeCard',{key:attendee.attendeeId,attrs:{"data":attendee}})}),1)])}),0)])]):_vm._e(),(_vm.sectionToUse.ads.useSection)?_c('AdSlot',{staticClass:"bg-ads",attrs:{"slot-name":"home"}}):_vm._e(),(
            _vm.sectionToUse.attendeeSwimlane.useSection &&
                !_vm.featuredAttendeesLoaded
        )?_c('Spinners',{staticClass:"py-8"}):(
            _vm.sectionToUse.attendeeSwimlane.useSection &&
                _vm.attendeeSwimlanesHasData
        )?_c('div',{staticClass:"pt-12 px-4 bg-cover",class:_vm.cssVariables['--home-sampling-attendee-bg-color'] != null
                ? 'bg-attendee-swimlane'
                : 'bg-white',style:(_vm.backgroundImageIfPresent(_vm.featuredAttendeeSectionBg))},[_c('div',{staticClass:"container"},[(_vm.getLayoutOptions.usesUtilityHeader)?_c('div',{staticClass:"mb-4"},[_c('svg-networking',{staticClass:"home-networking-icon"})],1):_vm._e(),_c('div',{staticClass:"container px-4 py-6"},_vm._l((_vm.attendeeSwimlane),function(swimlane){return _c('featured-attendee',{key:swimlane.category,attrs:{"header-label":swimlane.headerLabel,"header-centered":swimlane.headerCentered,"attendees":swimlane.data,"isSearchButtonActive":_vm.sectionToUse.attendeeSwimlane.isSearchButtonActive,"header-classes":"attendee-swimlane--header"}})}),1)])]):_vm._e(),(
            _vm.sectionToUse.featuredSolutionProvider.useSection &&
                (!_vm.tradeshowDataLoaded || _vm.hasTradeshowCompanies)
        )?_c('div',{staticClass:"section featured-section bg-solution-provider py-12",class:[
            ("order-" + (_vm.sectionToUse.featuredSolutionProvider.flexOrderSwimlane))
        ]},[_c('h2',{staticClass:"text-solution text-center"},[_vm._v(" "+_vm._s(_vm.sectionToUse.featuredSolutionProvider.swimlaneHeader)+" ")]),_c('div',{staticClass:"container px-1"},[_c(_vm.featuredSection,{tag:"component"})],1),(_vm.sectionToUse.featuredSolutionProvider.useMultiSwimlane)?_c('div',{staticClass:"w-full text-center pt-20 pb-12"},[_c('h2',[_vm._v(" "+_vm._s(_vm.sectionToUse.featuredSolutionProvider.multipleSwimlane .headerLabel)+" ")]),_c('div',{staticClass:"container px-1"},[_c(_vm.featuredSection,{tag:"component",attrs:{"isUser":'multiSwimlane'}})],1)]):_vm._e()]):_vm._e(),(_vm.sectionToUse.staticCompanyPartner.useSection)?_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"pt-4 pb-12",class:("order-" + (_vm.sectionToUse.staticCompanyPartner.flexOrderSwimlane))},_vm._l((_vm.staticSponsorData),function(company){return _c('StaticPartnerPage',{key:company.swimlaneCount,attrs:{"data":company.staticData,"swimlaneTitle":company.header}})}),1)]):_vm._e(),(_vm.sectionToUse.schedule.isActive)?_c('div',{staticClass:"container"},[_c('schedule')],1):_vm._e(),(_vm.useTwitterWidget)?_c('div',{staticClass:"px-4 lg:hidden my-4"},[_c('div',{staticClass:"container"},[_c('TwitterWidget')],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }