var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
        _vm.loading ||
            (_vm.options.actionIconSettings.enabled &&
                _vm.options.actionIconSettings.icons.length)
    )?_c('div',[(
            _vm.loading ||
                (_vm.options.actionIconSettings.enabled &&
                    _vm.options.actionIconSettings.icons.length)
        )?_c('div',{class:_vm.innerClasses,style:(("background: " + _vm.innerClasses))},[(_vm.loading)?_c('Spinners'):_c('div',{staticClass:"container"},[_c('h2',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"text-center w-full py-5 mb-0"},[_vm._v(" "+_vm._s(_vm.options.actionIconSettings.header)+" ")]),_c('div',{staticClass:"container grid gap-16 px-4",class:'md:grid-cols-3'},_vm._l((_vm.options.actionIconSettings.icons),function(item){return _c('div',{key:item.title},[_c('div',{staticClass:"m-auto flex flex-col justify-start items-center text-center"},[(item.link)?_c('a',{staticClass:"flex justify-center flex-col items-center no-underline",attrs:{"href":item.link === '/attendees/attendee-profile/'
                                    ? ("" + (item.link) + _vm.myOwnProfileId)
                                    : ("" + (item.link)),"target":item.openInNewTab ? '_blank' : '',"aria-label":item.title}},[_c('div',{staticClass:"bg-action w-24 h-24 rounded-full grid mb-2"},[_c('span',{staticClass:"svgSize w-full h-full flex justify-center items-center m-auto",domProps:{"innerHTML":_vm._s(item.imgLink)}})]),_c('div',{staticClass:"text-xl",staticStyle:{"color":"var(--action-button-bg)"}},[_vm._v(" "+_vm._s(item.title)+" ")])]):_c('div',{staticClass:"bg-action w-40 h-40 rounded-full grid"},[_c('div',{staticClass:"bg-action w-24 h-24 rounded-full grid mb-2"},[_c('span',{staticClass:"svgSize w-full h-full flex justify-center items-center m-auto",domProps:{"innerHTML":_vm._s(item.imgLink)}})]),_c('div',[_vm._v(" "+_vm._s(item.title)+" ")])]),_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.content)+" ")])])])}),0)])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }