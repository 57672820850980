import { render, staticRenderFns } from "./RecommendedStreams.vue?vue&type=template&id=28f3cc44&scoped=true&"
import script from "./RecommendedStreams.vue?vue&type=script&lang=js&"
export * from "./RecommendedStreams.vue?vue&type=script&lang=js&"
import style0 from "./RecommendedStreams.vue?vue&type=style&index=0&id=28f3cc44&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28f3cc44",
  null
  
)

export default component.exports