
















































import  Vue from "vue";
import { getModule } from "vuex-module-decorators";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import MgImage from "@/components/shared/MgImage.vue";

import { TradeshowCompanyObject } from "@/types/interfaces";

const tradeshowStore = getModule(tradeshowVuexModule);
import "swiper/css/swiper.css";


export default Vue.extend ({
    data() {
        return {
            
            isLoading : true,
            componentId : "",
        
            swiperOption : {
                slidesPerView: 1,
                spaceBetween: 30,
                preloadImages: false,
                lazy: true,
                watchSlidesProgress: true,
                loop: true,
                autoplay: {
                    delay: 8000,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 20
                    },
                    1024: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 40
                    },
                    1336: {
                        slidesPerView: this.numberOfslidesPerGroup,
                        slidesPerGroup: this.numberOfslidesPerGroup,
                        spaceBetween: 10
                    }
                },
                navigation: {}
            },
        }
    },
    props: {
        isUser: {
            default: "",
            type: String
        },
        hideCompanyName: {
            type: Boolean,
            default: false
        },
        numberOfslidesPerGroup: {
            type: Number,
            default: 4 
        }
        // @Prop({ default: "", required: false })
        // isUser!: "";
    
        // @Prop({ default: "", required: false })
        // hideCompanyName!: false;
    
        // @Prop({ default: 4, required: false })
        // numberOfslidesPerGroup!: 4;
    },
    components: {
        Swiper,
        SwiperSlide,
        MgImage
    },
    computed: {
        
        staticCompany(): TradeshowCompanyObject[] {
            if (this.tradeshowCompanies) {
                const com1 =
                    "CAEI | Conf. of the Americas on International Education";
                const com2 = "Universidad Loyola Andalucia";
                const com3 = "Gothenburg";
    
                return this.tradeshowCompanies.filter((data: any) => {
                    if (data.companyName) {
                        if (
                            data.companyName.toLowerCase() === com1.toLowerCase() ||
                            data.companyName.toLowerCase() === com2.toLowerCase() ||
                            data.companyName.toLowerCase() === com3.toLowerCase()
                        ) {
                            return data;
                        }
                    }
                });
            }
            return [];
        },
    
        tradeshowCompanies(): TradeshowCompanyObject[] {
            let categoryFilter = "";
            if (this.isUser) {
                categoryFilter = this.$store.getters.getPageOptions("home").section
                    .featuredSolutionProvider.multipleSwimlane.sponsorCategory;
            } else {
                categoryFilter = this.$store.getters.getPageOptions("home").section
                    .featuredSolutionProvider.sponsorCategory;
            }
    
            const companies = tradeshowStore.allTradeshowCompanies;
    
            return companies
                .filter((item: TradeshowCompanyObject) => {
                    if (!categoryFilter) {
                        return item;
                    } else if (categoryFilter.includes(item.level as string)) {
                        return item;
                    }
                })
                .sort((cur: any, nxt: any) => {
                    if (cur.companyName) {
                        return cur.companyName.localeCompare(nxt.companyName);
                    } else return cur.name.localeCompare(nxt.name);
                });
        },
    
        fallbackImage(): string {
            const logo = this.$store.getters.getPageOptions("tradeshow")
                .defaultLogo;
    
            return logo ? `/bg/${logo}` : "";
        }
    },


    created() {
        this.componentId = Vue.prototype.MgGetUUID();

        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });

        // Options depending on componentId
        this.swiperOption.navigation = {
            nextEl: `.swiper-button-next_${this.componentId}`,
            prevEl: `.swiper-button-prev_${this.componentId}`
        };
    },
    methods: {
        getImgSrcset(exhibitor: TradeshowCompanyObject) {
            let returnPath = "";
            const set = [];
    
            if (exhibitor?.images?.logo) {
                const urls = exhibitor.images.logo;
                const img400 = urls["400x400"];
                const img800 = urls["800x800"];
    
                if (img400) {
                    set.push(`${img400} 600w`);
                }
    
                if (img800) {
                    set.push(`${img800} 2048w`);
                }
    
                returnPath = set.join(",");
            }
    
            return returnPath;
        },
    
        // METHODS
        getImgPath(exhibitor: TradeshowCompanyObject) {
            const logo = exhibitor?.images?.logo;
            let returnPath = "";
            if (logo && "string" === typeof logo) {
                returnPath = logo;
            } else if (exhibitor.imageLogo) {
                returnPath = exhibitor.imageLogo;
            } else {
                returnPath = `/bg/${
                    this.$store.getters.getPageOptions("tradeshow").defaultLogo
                }`;
            }
    
            return returnPath;
        }

    }
})
