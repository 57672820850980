





























































































































































































































































































// Required imports
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import Vue from "vue";

// Components
import recommendedStreams from "@/components/RecommendedStreams.vue";
import VideoComponent from "@/components/shared/VideoComponent.vue";
import Spinners from "@/components/utilities/Spinners.vue";
import SessionSwimLane from "@/components/sessions/SessionSwimLane.vue";
import featuredSection from "@/components/home/section/featuredSection.vue";
import TradeshowSwimlaneWithDifferentLogoSizes from "@/components/home/section/TradeshowSwimlaneWithDifferentLogoSizes.vue";
import ExhibitorSwimlane from "@/components/home/section/ExhibitorSwimlane.vue";
import ActionIcon from "@/components/home/section/ActionIcon.vue";
import StaticPartnerPage from "@/components/home/staticSection/StaticPartnerPage.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import AdSlot from "@/components/shared/AdSlot.vue";
import TradeshowSwimlaneWithHeaderTier from "@/components/tradeshow/TradeshowSwimlaneWithHeaderTier.vue";
import SvgNetworking from "@/components/svg/svg-networking.vue";
import TwitterWidget from "@/components/shared/TwitterWidget.vue";

// Vuex modules
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
import featuredVideoVuexModule from "@/store/vuex-modules/getFeaturedVideo";
import featuredAttendeeVuexModule from "@/store/vuex-modules/getFeaturedAttendees";
import featuredContentVuexModule from "@/store/vuex-modules/featuredContent";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";

// Types
import {
    SessionDataArray,
    FeaturedVideoObject,
    FeaturedContentObject,
    AttendeeSwimlaneObject
} from "@/types/interfaces";

// Define Vuex module variables
const layoutImagesStore = getModule(layoutImagesVuexModule);
const sessionStore = getModule(sessionVuexModule);
const featuredVideoStore = getModule(featuredVideoVuexModule);
const featuredContentStore = getModule(featuredContentVuexModule);
const featuredAttendeeStore = getModule(featuredAttendeeVuexModule);
const tradeshowStore = getModule(tradeshowVuexModule);

export default Vue.extend({
    data() {
        return {
            posterImage: "",
            prev: "",
            session: "",
            bg: "",
            color: "",
            live: "",
            // chatter: "",

            videoBackgroundImage: "",
            isLoadingVideo: true,
            isLoadingSessions: true,
            isLoadingFeaturedContent: true,
            recommendedStreamsSection: "",
            featuredSection: "",

            isFeaturedAttendeeDefaultTemplate: false,
            staticSponsorData: ""
        };
    },
    components: {
        recommendedStreams,
        VideoComponent,
        "featured-attendee": () => {
            let returnResult = store.getters.getPageOptions("home").section
                ?.attendeeSwimlane?.componentToLoad;
            if (!returnResult) {
                returnResult = "AttendeesTaxonomySwimlane.vue";
            }

            return import(`@/components/attendees/swimlanes/${returnResult}`);
        },
        SessionSwimLane,
        Spinners,
        featuredSection,
        ExhibitorSwimlane,
        TradeshowSwimlaneWithDifferentLogoSizes,
        ActionIcon,
        StaticPartnerPage,
        AttendeeCard,
        AdSlot,
        TradeshowSwimlaneWithHeaderTier,
        SvgNetworking,
        TwitterWidget,
        schedule: () => {
            const componentToUSe = store.getters.getPageOptions("home").section
                .schedule.component;
            return import(`@/views/${componentToUSe}`);
        },
        TradeshowColumns: () =>
            import(
                /* webpackChunkName: "Tradeshow" */ "@/components/tradeshow/TradeshowColumns.vue"
            )
    },

    computed: {
        /**
         * Getters / computed
         */
        // get staticSponsorData() {
        //     if (
        //         store.getters.getPageOptions("home").section
        //             .staticCompanyPartner.swimlaneData
        //     ) {
        //         return store.getters.getPageOptions("home").section
        //             .staticCompanyPartner.swimlaneData;
        //     }
        //     return [];
        // }

        notProduction(): boolean {
            return Boolean("production" !== process.env.NODE_ENV);
        },

        useVideoBgOverlay(): boolean {
            return this.homeOptions.section.videoContainer.useVideoBgOverlay 
        },

        homeOptions(): Record<string, any> {
            return store.getters.getPageOptions("home");
        },

        sectionToUse(): Record<string, any> {
            return this.homeOptions.section;
        },

        siteClient(): string {
            return this.getLayoutOptions.siteName;
        },

        tradeshowDataLoaded(): boolean {
            return tradeshowStore.tradeshowDataLoaded;
        },

        hasTradeshowCompanies(): boolean {
            const companies = tradeshowStore.allTradeshowCompanies || [];
            return Boolean(companies.length);
        },

        bannerOption(): boolean {
            if (store.getters.getPageOptions("banner")) {
                const bannerOption = store.getters.getPageOptions("banner");
                return bannerOption?.bannerAds?.isActive ? true : false;
            } else {
                return false;
            }
        },

        recommendedSectionBg(): Record<string, string> {
            return layoutImagesStore.getLayoutImage("recommendedSection");
        },

        featuredAttendeeSectionBg(): string {
            return layoutImagesStore.getLayoutImage("featuredAttendeeHome");
        },

        cssVariables(): Record<string, string> {
            return store.getters.cssVariables;
        },

        homeLabel(): Record<string, string> {
            return store.getters.homeLabel;
        },

        getLayoutOptions() {
            return store.getters.layoutOptions;
        },

        sessionData(): SessionDataArray {
            return sessionStore.sessionPromos ? sessionStore.sessionPromos : [];
        },

        featuredVideo(): FeaturedVideoObject {
            return featuredVideoStore.featuredVideo;
        },
        featuredItem(): string {
            return this.sectionToUse.featuredItem;
        },

        featuredContent(): Array<FeaturedContentObject> {
            return featuredContentStore.featuredContent;
        },

        myOwnProfileId(): string {
            return store.getters.myBespeakeId;
        },

        attendeeSwimlanesHasData(): boolean {
            return featuredAttendeeStore.hasData;
        },

        attendeeSwimlane(): Array<AttendeeSwimlaneObject> {
            return featuredAttendeeStore.featuredAttendeeSwimlanes || [];
        },

        featuredAttendeesLoaded(): boolean {
            return featuredAttendeeStore.featuredAttendeesLoaded;
        },

        pageOptions(): Record<string, any> {
            return store.getters.getPageOptions("attendees");
        },
        attendeeSwimlanes(): Array<any> {
            return this.pageOptions.swimLanes ? this.pageOptions.swimLanes : [];
        },

        attendeeTier(): Array<string> {
            return this.attendeeSwimlane.flatMap((item: any) => {
                return item.data;
            });

            // return att.filter((item, index) => index !== 0);
        },

        siteName(): string {
            return store.state.themeConfig.conferenceName;
        },

        useTwitterWidget(): boolean {
            return Boolean(this.homeOptions.section?.twitterWidget?.active);
        },

        useRecommendedSection(): boolean {
            return this.sectionToUse.recommendedStreams.useSection;
        }
    },

    /**
     * Lifecycle
     */

    created() {
        this.init();
        this.loadTwitterEmbed();
    },
    mounted() {
        this.getFeaturedSection();
    },

    /**
     * Methods
     */

    methods: {
        init() {
            const promises = [
                featuredVideoStore.getFeaturedVideo(),
                featuredContentStore.getFeaturedContent(),
                featuredAttendeeStore.getFeaturedAttendees(
                    this.attendeeSwimlanes
                )
            ];

            if (this.useRecommendedSection) {
                promises.push(
                    sessionStore.getSessionData({
                        isDemoData: false
                    })
                );
            } else {
                this.isLoadingSessions = false;
            }

            Promise.allSettled(promises).then(() => {
                this.isLoadingVideo = false;
                this.isLoadingSessions = false;
                this.isLoadingFeaturedContent = false;
            });

            if (
                this.sectionToUse.attendeeSwimlane?.useSection &&
                Array.isArray(this.sectionToUse.attendeeSwimlane?.swimlanes) &&
                this.sectionToUse.attendeeSwimlane?.swimlanes.length
            ) {
                featuredAttendeeStore.getFeaturedAttendees(
                    this.sectionToUse.attendeeSwimlane.swimlanes
                );
            }
        },



        bgColor(section: string) {
            if (section == "previous") {
                return this.cssVariables["--home-prev-session-bg-color"] != ""
                    ? "bg-prev-session"
                    : "bg-gradient";
            } else if (section == "featured-section") {
                return this.cssVariables["--home-solution-provider-bg-color"] !=
                    ""
                    ? "bg-featured-solution"
                    : "bg-gradient";
            } else {
                return null;
            }
        },

        textColor(section: string) {
            if (section == "slider-arrows") {
                return this.cssVariables["--home-prev-session-text-color"] != ""
                    ? "home-prevsession-text-color"
                    : "";
            }
        },

        loadTwitterEmbed() {
            window.twttr.widgets.load(
                document.getElementById("twitter-timeline--embed")
            );
        },

        backgroundImageIfPresent(imageVar: string, section?: string, isOverlay?: boolean) {
            if (imageVar) {
                if (isOverlay && section == "videoBackgroundImage") {
                    return {
                        "background-image": `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${imageVar})`
                    };
                }
                 return {
                    "background-image": `url(${imageVar})`
                 };
            } else {
                return {};
            }
        },
        getFeaturedSection() {
            const bgResult = this.sectionToUse.videoContainer.bgDecor;

            this.featuredSection = this.sectionToUse.featuredSolutionProvider
                .componentToLoad
                ? this.sectionToUse.featuredSolutionProvider.componentToLoad
                : "featuredSection";

            this.isFeaturedAttendeeDefaultTemplate =
                this.sectionToUse.attendeeSwimlane.componentToLoad || false;

            this.staticSponsorData = this.sectionToUse.staticCompanyPartner.swimlaneData;

            this.videoBackgroundImage = bgResult ? `/bg/${bgResult}` : "";
        }
    }
});
