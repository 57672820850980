import Vue from "vue";
import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import Store from "../index";
import { getApiClient } from "@/services/api";

// This module is only for functionality related to the following endpoint:
const endpoint = "featured-content";

import { FeaturedContentObject } from "@/types/interfaces";

@Module({
    dynamic: true,
    store: Store,
    name: "FeaturedContentModule",
    namespaced: true
})
export default class FeaturedContentModule extends VuexModule {
    featuredContent: Array<FeaturedContentObject> = [];

    @Mutation
    public setfeaturedContent(data: Array<FeaturedContentObject>) {
        this.featuredContent = data;
    }

    @Action({ commit: "setfeaturedContent" })
    getFeaturedContent() {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`${endpoint}`, {
                    headers: {
                        Authorization: `bearer ${token}`
                    }
                })
                .then(response => {
                    // TODO ideally, the API should return data sorted
                    // delete this sorting if the following gets done:
                    // https://matrix-group.atlassian.net/browse/VMP-677?focusedCommentId=48237
                    const returnValue = Vue.prototype.MgSortByProperty(
                        response.data,
                        "orderIndex"
                    );

                    return resolve(returnValue);
                })
                .catch(error => {
                    return reject(error);
                });
        });
    }
}
