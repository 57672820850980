

















































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import { TradeshowCompanyObject } from "@/types/interfaces";
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";
import Spinners from "@/components/utilities/Spinners.vue";
// import ExhibitorResultCard from "@/components/shared/ExhibitorResultCard.vue";

const tradeshowStore = getModule(tradeshowVuexModule);


export default Vue.extend ({
    data() {
        return {
            isLoading: true,
            featuredHeaderAlignment:""
        }
    },
    components: {
        Spinners,
        // ExhibitorResultCard
    },
    computed: {
        sponsorToLoad(): string[] {
            const res = this.$store.getters.getPageOptions("home").section;
            const sponsorType = res.featuredSolutionProvider.sponsorCategory;
    
            return sponsorType;
        },
        tradeshowCompanies(): TradeshowCompanyObject[] {
            let companies = tradeshowStore.allTradeshowCompanies || [];
    
            const filteredSection = this.pageOptions;
    
            if (
                filteredSection.section?.featuredSolutionProvider.filteredforPromo
            ) {
                companies = companies.filter(
                    (item: TradeshowCompanyObject) => item.forPromo
                );
            }
    
            return companies;
        },       
        
        cssVariables(): Record<string, string> {
            return this.$store.getters.cssVariables;
        },
        headerAlignment(): string {
            return this.$store.getters.getPageOptions("home").section
                .featuredSolutionProvider.labelHeaderAlignment
                ? "text-center"
                : "";
        },
        pageOptions(): Record<string, Record<string, Record<string, string>>> {
            return this.$store.getters.getPageOptions("home");
        },
        pageConfig(): Record<string, string> {
            return this.$store.getters.getPageOptions("tradeshow");
        },
        homeLabel(): string {
            return this.$store.getters.homeLabel;
        },
        showDescriptions(): string {
            return this.pageOptions.section?.featuredSolutionProvider
                .showDescriptions;
        },
        tradeshowCompaniesSorted(): TradeshowCompanyObject[] {
            const companies = this.tradeshowCompanies;
    
            companies.sort(
                (cur: TradeshowCompanyObject, nxt: TradeshowCompanyObject) => {
                    const curName = cur.companyName
                        ? cur.companyName
                        : cur.name
                        ? cur.name
                        : "";
                    const nextName = nxt.companyName
                        ? nxt.companyName
                        : nxt.name
                        ? nxt.name
                        : "";
    
                    return curName.localeCompare(nextName);
                }
            );
    
            if (this.pageConfig.sortByTierInOneLane) {
                companies.sort(
                    (cur: TradeshowCompanyObject, nxt: TradeshowCompanyObject) => {
                        const curLevel = cur.level ? parseInt(cur.level) : 0;
                        const nxtLevel = nxt.level ? parseInt(nxt.level) : 0;
    
                        return nxtLevel - curLevel;
                    }
                );
            }
    
            return companies;
        }

    },


    created() {
        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });
    }

    /**
     * methods
     */
})
