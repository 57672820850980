















































import Vue from "vue";

export default Vue.extend ({
    props: {
       data: {
        type: [],
        default: ""
       },
       swimlaneTitle: {
        type: String,
        default: ""
       }
    // @Prop({
    //     default: ""
    // })
    // data!: [];

    // @Prop({
    //     default: ""
    // })
    // swimlaneTitle!: "";

    },
    computed: {
        pageOption() {
            const options = this.$store.getters.getPageOptions("home");
            return options.section.staticCompanyPartner;
        }

    },
    methods: {
        getImage(logoName: string) {
            const fallback = this.pageOption.fallBackImage;
            if (logoName) {
                return `/staticSponsorLogo/${logoName}`;
            } else {
                return `/bg/${fallback}`;
            }
        }
    }
})
