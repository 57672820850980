
























































































import Vue from "vue";
// import { defineComponent } from "vue";

import { SiteOptions } from "@/types/interfaces";
import Spinners from "@/components/utilities/Spinners.vue";

export default Vue.extend({
    components: {
        Spinners
    },
    props: {
        innerClasses: {
            type: String,
            default: "bg-white py-10"
        }
    },
    data() {
        return {
            loading: true
        };
    },
    computed: {
        optionsStore(): any {
            return this.$store.state.optionsVuexModule;
        },
        options(): SiteOptions {
            return this.optionsStore.options;
        },
        myOwnProfileId(): string {
            return this.$store.getters.myBespeakeId;
        }
    },
    mounted() {
        this.getIcons();
    },
    methods: {
        async getIcons() {
            await this.$store.dispatch("getOptions", ["actionIconSettings"]);
            this.loading = false;
        }
    }
});
