


























































































































































































// Base
import Vue from "vue";
import { getModule } from "vuex-module-decorators";

// Utility
import eventHub from "@/event-hub";
import { a11yFixBuefyNotificationAriaAttrs } from "@/services/a11y";

// Components

import Spinners from "@/components/utilities/Spinners.vue";
import MgImage from "@/components/shared/MgImage.vue";

// Types
import {
    TradeshowCompanyObject,
    TradeshowSwimlaneTier
} from "@/types/interfaces";

// Vuex modules
import tradeshowVuexModule from "@/store/vuex-modules/getTradeshowData";

const tradeshowStore = getModule(tradeshowVuexModule);

const componentId = Vue.prototype.MgGetUUID();
const notificationId = `notification-${componentId}`;


export default Vue.extend ({
    data(){
        return {
            isLoading: true,
            isSponsorEmpty: false,
            isDataLoaded: false,
            searchQuery: "",
            executedSearchQuery: ""
        }
    },
    components: {
        Spinners,
        MgImage
    },
    directives: {
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit(notificationId, el);
            }
        }
    },
    props:{
        accessor: {
            type: String,
            default: ""
        }
    },
    computed: {
       pageConfig(): Record<string, any> {
            return this.$store.getters.getPageOptions("tradeshow") || {};
        },

       textHeader(): string {
            return this.pageConfig?.textHeader;
        },

       isUsedBy(): string | undefined | null {
            return this.$router.currentRoute.name;
        },

       hasTradeshowCompanies(): boolean {
            const companies = tradeshowStore.allTradeshowCompanies || [];
            return Boolean(companies.length);
        },

       tradeshowCompanies(): Array<TradeshowCompanyObject> {
            let companies = tradeshowStore.allTradeshowCompanies;

            const query = this.executedSearchQuery.toLowerCase();

            const searchFields = ["companyName"] as Array<
                keyof TradeshowCompanyObject
            >;

            if (this.executedSearchQuery != "") {
                companies = companies.filter((company) => {
                    let hasTerm = false;

                    searchFields.forEach((searchField) => {
                        const data = company[searchField];

                        if (data && typeof data == "string") {
                            if (data.toLowerCase().includes(query)) {
                                hasTerm = true;
                            }
                        }
                    });

                    return hasTerm;
                });
            }

            return companies.sort(
                (cur: TradeshowCompanyObject, nxt: TradeshowCompanyObject) => {
                    const curName = cur.companyName
                        ? cur.companyName
                        : cur.name
                        ? cur.name
                        : "";

                    const nextName = nxt.companyName
                        ? nxt.companyName
                        : nxt.name
                        ? nxt.name
                        : "";

                    return curName.localeCompare(nextName);
                }
            );
        },

       cssVariables(): Record<string, string> {
            return this.$store.getters.cssVariables;
        },

       homeLabel(): Record<string, string> {
            return this.$store.getters.homeLabel;
        },

       headerAlignment(): string {
            return this.$store.getters.getPageOptions("home").section
                .featuredSolutionProvider.labelHeaderAlignment
                ? "text-center"
                : "";
        },

       pageDecorImage(): string {
            let returnValue = "";
            if (
                this.pageConfig.decorImages &&
                this.pageConfig.decorImages.pageBackground
            ) {
                returnValue = `/bg/${this.pageConfig.decorImages.pageBackground}`;
            }
            return returnValue;
        },

       exhibitorNameLabel(): string {
            return this.pageConfig.headerTierSearchLabel
                ? this.pageConfig.headerTierSearchLabel
                : "Exhibitors";
        },
       companyGroupByTier(): Array<TradeshowCompanyObject> {
            return this.checkTierMerged();
        }
    },
    methods: {

        checkTierMerged() {
            const chck = this.$store.getters.getPageOptions("home");

            // WIP Temporary, i'll fix it when in no rush
            if (chck.section.featuredSolutionProvider.mergeCompanyTier) {
                const tiers: Array<TradeshowSwimlaneTier> = [];

                chck.section.featuredSolutionProvider.mergeCompanyTier.forEach(
                    (data: TradeshowSwimlaneTier) => {
                        const tierObj = {
                            ...data,
                            companies: this.tradeshowCompanies.filter(
                                (item: TradeshowCompanyObject) => {
                                    const levels = data.level;
                                    if (item.level) {
                                        return levels.includes(item.level);
                                    }
                                }
                            )
                        };

                        tiers.push(tierObj);
                    }
                );
                return tiers;
            } else {
                const tiers: Array<TradeshowSwimlaneTier> = [];

                this.pageConfig.tiers.forEach((data: TradeshowSwimlaneTier) => {
                    const tierObj = {
                        ...data,
                        companies: this.tradeshowCompanies.filter(
                            (item: TradeshowCompanyObject) => {
                                const levels = data.level;
                                if (item.level) {
                                    return levels.includes(item.level);
                                }
                            }
                        )
                    };

                    tiers.push(tierObj);
                });
                return tiers;
            }
        },

        getLogoImage(exhibitor: TradeshowCompanyObject) {
            const logo = exhibitor?.images?.logo;
            let returnPath = "";
            if (logo && "string" === typeof logo) {
                returnPath = logo;
            } else if (exhibitor.imageLogo) {
                returnPath = exhibitor.imageLogo;
            } else {
                returnPath = `/bg/${this.pageConfig.defaultLogo}`;
            }

            return returnPath;
        },

        getLogoSrcset(exhibitor: TradeshowCompanyObject) {
            let returnPath = "";
            const set = [];

            if (exhibitor?.images?.logo) {
                const urls = exhibitor.images.logo;
                const img400 = urls["400x400"];
                const img800 = urls["800x800"];

                if (img400) {
                    set.push(`${img400} 600w`);
                }

                if (img800) {
                    set.push(`${img800} 2048w`);
                }

                returnPath = set.join(",");
            }

            return returnPath;
        },

        logPageVisit() {
            const logData = {
                type: `SponsorLanding`
            };

            this.$store
                .dispatch("appendLogEntry", logData)
                .catch((err) => console.log(err));
        },

        handleBuefyNotificationFixes(el: HTMLElement) {
            a11yFixBuefyNotificationAriaAttrs(el);
        },

        executeSearch() {
            this.executedSearchQuery = this.searchQuery;
        },

        resetSearch() {
            this.searchQuery = "";
            this.executedSearchQuery = "";
        }

    },

    created() {
        this.logPageVisit();

        tradeshowStore.getTradeshowCompanies().finally(() => {
            this.isLoading = false;
        });

        eventHub.$on(notificationId, this.handleBuefyNotificationFixes);
    },

    beforeDestroy() {
        eventHub.$off(notificationId, this.handleBuefyNotificationFixes);
    }
})
