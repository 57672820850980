




































































































































































import eventHub from "@/event-hub";
import { a11yFixBuefyModalAriaAttrs } from "@/services/a11y";
import { SessionDataObject, FeaturedContentObject } from "@/types/interfaces";
import Vue from "vue";

interface ActiveList {
    id?: number;
    sessionId?: string;
    imgPath?: string;
    tittle?: string;
    speakers?: string;
    company?: string;
    description?: string;
    position?: string;
    url?: string;
}

export default {
    data() {
        return {
            componentId: "",
            isLoading: false,
            isModalOpen: false,
            activeItemIsSponsor: false,
            activeListId: "0",
            optionsReady: false,
            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 30,
                loop: false, // there is a bug in loop mode where order gets messed up and modal does not work in some cases
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: true
                },
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 20
                    },
                    1024: {
                        slidesPerView: 2,
                        slidesPerGroup: 2,
                        spaceBetween: 40
                    },
                    1336: {
                        slidesPerView: 3,
                        slidesPerGroup: 3,
                        spaceBetween: 10
                    }
                },
                navigation: {}
            }
        };
    },
    props: {
        sessionData: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        contentType: {
            type: String,
            default: ""
        },
        arrowColor: {
            type: String,
            default: ""
        }
    },
    directives: {
        addbuefymodal: {
            bind: function(el: HTMLElement) {
                eventHub.$emit("BuefyModalBound", el);
            }
        }
    },
    computed: {
        showActiveListOnModal(): ActiveList | SessionDataObject | undefined {
            let lookIn = (this as any).sessionData;

            if ((this as any).activeItemIsSponsor) {
                lookIn = (this as any).sponsorVideos;
            }

            const showCase = lookIn.find((item: any) => {
                let element;
                if ("featuredContent" === (this as any).contentType) {
                    element = item as FeaturedContentObject;
                    return element.itemId === (this as any).activeListId;
                } else {
                    element = item as SessionDataObject;
                    return element.sessionId === (this as any).activeListId;
                }
            });
            return showCase;
        },

        speakersString() {
            const speakers = (this as any)?.showActiveListOnModal?.speakers;
            let returnValue = "";
            if (!speakers || 0 === speakers.length) return returnValue;

            if (Array.isArray(speakers)) {
                returnValue = speakers.map((item) => item.name).join(", ");
            }

            return returnValue;
        },

        sponsorVideos() {
            return (this as any).$store.getters.sponsorVideos;
        },

        displayedArrowColor() {
            return (this as any).arrowColor
                ? (this as any).arrowColor
                : "text-accent";
        }
    },

    /**
     * Lifecycle
     */
    created() {
        (this as any).componentId = Vue.prototype.MgGetUUID();

        // Options depending on componentId
        (this as any).swiperOption.navigation = {
            nextEl: `.swiper-button-next_${(this as any).componentId}`,
            prevEl: `.swiper-button-prev_${(this as any).componentId}`
        };
        (this as any).optionsReady = true;

        eventHub.$on("BuefyModalBound", (this as any).handleBuefyModalFixes);
    },

    beforeDestroy() {
        eventHub.$off("BuefyModalBound", (this as any).handleBuefyModalFixes);
    },
    methods: {
        /**
         * Methods
         */
        handleItemClick(session: SessionDataObject) {
            if (
                session.itemId &&
                "featuredContent" === (this as any).contentType
            ) {
                (this as any).activeListId = session.itemId;
            } else {
                (this as any).activeListId = session.sessionId;
            }

            if (
                (this as any).showActiveListOnModal &&
                (this as any).showActiveListOnModal.url
            ) {
                location.href = (this as any).showActiveListOnModal.url;
            } else {
                (this as any).showModal();
            }
        },

        showModal() {
            (this as any).isModalOpen = true;
        },

        closeModal(): boolean {
            return ((this as any).isModalOpen = false);
        },

        handleBuefyModalFixes(el: HTMLElement) {
            a11yFixBuefyModalAriaAttrs(el);
        }
    }
};
